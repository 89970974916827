import React, { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from "react";

import Button from "@material-ui/core/Button";
import clsx from "clsx";
import Compressor from "compressorjs";
import { AiOutlineFilePdf } from "react-icons/ai";
import Swal from "sweetalert2";

import { FileCell, ImgContainer, UploadItem } from "~/components/Appointments/Styles/UploadImages";
import CheckCircleGreenFilledIcon from "~/icons/CheckCircleGreenFilledIcon";
import PlusCircleBlueIcon from "~/icons/PlusCircleBlueIcon";
import TrashIcon from "~/icons/TrashIcon";
import { groupedMedicalOrderAPI, medicalOrderAPI } from "~/utils/api/v2";
import { AppointmentPatientV2 } from "~/utils/interfaces/AppointmentPatient";
import { MedicalOrder } from "~/utils/interfaces/MedicalOrder";

interface UploadImageProps {
  groupedMedicalOrders: string[];
  setGroupedMedicalOrders: Dispatch<SetStateAction<string[]>>;
  appointmentPatient: AppointmentPatientV2;
  hasImage?: boolean;
  setHasImage?: (hasImage: boolean) => void;
  fileArray: File[] | undefined;
  setFileArray: React.Dispatch<React.SetStateAction<File[] | undefined>>;
}

const FileUploadForm = (props: UploadImageProps) => {
  const [fileNameArray, setFileNameArray] = useState<Array<string>>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [medicalOrders, setMedicalOrders] = useState<MedicalOrder[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { appointmentPatient, fileArray, setFileArray } = props;

  const uploadMedicalOrderPic = async (): Promise<void> => {
    setUploading(true);
    try {
      let firstGroupID = "";
      const formData = new FormData();
      if (appointmentPatient.id) {
        formData.append("appointment_patient_id", appointmentPatient.id);
      }
      if (fileArray) {
        fileArray.forEach((file: File) => {
          formData.append("files", file);
        });
      }
      if (props.groupedMedicalOrders.length == 0) {
        const res = await groupedMedicalOrderAPI.post("betty", appointmentPatient.id);
        firstGroupID = res.data.id;
        props.setGroupedMedicalOrders([res.data.id]);
      }
      const responses = await Promise.all(
        fileArray?.map(async (file) => {
          return await medicalOrderAPI.post(
            file,
            props.groupedMedicalOrders[0] || firstGroupID,
            "betty",
            appointmentPatient.id,
          );
        }),
      );
      setMedicalOrders((prev) => [...prev, ...responses.map((res) => res.data)]);
      await Swal.fire({
        icon: "success",
        title: "Órdenes médicas subidas correctamente",
      });
      props.setFileArray([]);
    } catch (error) {
      console.log(error);
      await Swal.fire({
        icon: "error",
        title: "Error al subir las órdenes médicas",
      });
    }
    setUploading(false);
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    if (event.target.files && event.target.files.length === 1 && fileArray) {
      const fileToUpload = event.target.files[0];
      if (fileToUpload) {
        if (fileToUpload.size > 1_000_000 && fileToUpload.type != "application/pdf") {
          new Compressor(fileToUpload, {
            quality: 0.2,
            success(result: File) {
              const compressedFile = new File([result], result.name);
              setFileNameArray([...fileNameArray, compressedFile.name]);
              setFileArray([...fileArray, compressedFile]);
            },
          });
        } else if (fileToUpload.size > 1_000_000 && fileToUpload.type === "application/pdf") {
          Swal.fire({
            icon: "error",
            title:
              "Lo sentimos, no podemos procesar archivos pdf de más de 1mb. Prueba subiendo una foto o contactándonos por chat",
          });
        } else {
          setFileNameArray([...fileNameArray, fileToUpload.name]);
          setFileArray([...fileArray, fileToUpload]);
        }
      }
    }
    setUploading(false);
  };

  const removeUploadedFile = (index: number) => {
    setFileArray((prevState) => prevState?.filter((_, idx) => idx !== index));
    setFileNameArray((prevState) => prevState?.filter((_, idx) => idx !== index));
  };

  const handleClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const renderFileUploadProgressBar = () => (
    <div>
      {fileNameArray.map((fileName: string, i: number) => (
        <UploadItem key={i}>
          {!uploading && <CheckCircleGreenFilledIcon />}
          <p className={clsx(uploading ? "text-secondary" : "text-success")}>{uploading ? "Subiendo" : "Completado"}</p>
          <p>{fileName.substring(0, 70)}...</p>

          {!uploading && (
            <Button
              color="default"
              onClick={() => removeUploadedFile(i)}
            >
              <TrashIcon color="#BDBDBD" />
            </Button>
          )}
        </UploadItem>
      ))}
      <Button
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        Subir otro archivo
        <PlusCircleBlueIcon
          width={20}
          height={20}
          fill="#2F80ED"
        />
      </Button>
      <input
        type="file"
        accept="application/pdf, image/*"
        disabled={uploading}
        onChange={handleFileInputChange}
        ref={inputRef}
        hidden
      />
    </div>
  );

  useEffect(() => {
    const fetchMedicalOrders = async () => {
      try {
        const response = await medicalOrderAPI.list(props.groupedMedicalOrders, appointmentPatient.id);
        setMedicalOrders(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (props.groupedMedicalOrders.length > 0 && appointmentPatient.id) {
      fetchMedicalOrders();
    }
  }, [props.groupedMedicalOrders, appointmentPatient.id]);

  return (
    <div>
      {medicalOrders.map((medicalOrder, i: number) => {
        const id = medicalOrder.id;
        const link = medicalOrder.appointment_file_link;
        return (
          <Fragment key={id}>
            {!link.includes("pdf") && (
              <ImgContainer>
                <img
                  style={{
                    cursor: "pointer",
                    marginBottom: "10px",
                    marginTop: "10px",
                    marginRight: "auto",
                  }}
                  src={link}
                  alt="action register"
                  height="150px"
                  onClick={() => window.open(link, "_blank")}
                  title="Click para ver la imagen completa"
                />
              </ImgContainer>
            )}
            {link.includes("pdf") && (
              <a
                target="_blank"
                href={link}
                rel="noreferrer"
              >
                <FileCell>
                  <AiOutlineFilePdf size={38} />
                  <p>Archivo {i + 1}</p>
                </FileCell>
              </a>
            )}
          </Fragment>
        );
      })}
      {fileArray?.length == 0 && (
        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            Seleccionar archivo
          </Button>
          <input
            type="file"
            accept="application/pdf, image/*"
            disabled={uploading}
            onChange={handleFileInputChange}
            ref={inputRef}
            hidden
          />
        </div>
      )}
      {fileArray && fileArray?.length > 0 && renderFileUploadProgressBar()}
      <Button
        color="secondary"
        variant="contained"
        disabled={uploading || (fileArray && fileArray.length === 0)}
        onClick={uploadMedicalOrderPic}
      >
        {uploading ? "Cargando..." : "Subir archivo(s)"}
      </Button>
    </div>
  );
};

export default React.memo(FileUploadForm);
