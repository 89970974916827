import React, { useEffect, useState } from "react";

import { Popconfirm, Select, Skeleton, Spin, message, notification } from "antd";
import clsx from "clsx";

import alliancesService from "~/utils/api/v1/alliancesService";
import appointmentService from "~/utils/api/v1/appointmentService";
import { appointmentAPI } from "~/utils/api/v2";
import { newSalesSources } from "~/utils/constants/appointment";

type SalesSourceImageLoadProps = {
  salesSource?: string;
};

function SalesSourceImageLoad({ salesSource }: SalesSourceImageLoadProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const imgElement = React.useRef<HTMLImageElement>(null);

  useEffect(() => {
    function handleOnLoad() {
      setIsLoading(false);
      setIsError(false);
    }

    function handleImageError() {
      setIsLoading(false);
      setIsError(true);
    }

    const imgElCurrent = imgElement.current;

    if (imgElCurrent) {
      imgElCurrent.addEventListener("load", handleOnLoad);
      imgElCurrent.addEventListener("error", handleImageError);
      return () => {
        imgElCurrent.removeEventListener("load", handleOnLoad);
        imgElCurrent.removeEventListener("error", handleImageError);
      };
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
  }, [salesSource]);

  return (
    <div className="w-fit flex items-center h-9 max-w-[5rem]">
      <img
        ref={imgElement}
        src={`https://provider-logos-2.s3.us-east-1.amazonaws.com/${salesSource}.png`}
        className={clsx("object-contain h-7 hidden", !isLoading && !isError && "!block")}
      />
    </div>
  );
}

type SelectType = {
  label: string;
  value: string;
};

type AppointmentFilterProps = {
  isLoading?: boolean;
  appointmentId?: string;
  salesSource?: string;
  className?: string;
  onChange: (value: string) => void;
};

function SalesSourceSelect({
  isLoading: externalIsLoading,
  appointmentId,
  salesSource,
  className,
  onChange,
}: AppointmentFilterProps) {
  const [selected, setSelected] = useState<string | undefined>();
  const [preSelected, setPreSelected] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingChangeSalesSource, setIsLoadingChangeSalesSource] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [list, setList] = useState<SelectType[]>([]);

  function handleOnCancelChange() {
    setOpen(false);
  }

  function handleChange(value: string) {
    setPreSelected(value);
    setOpen(true);
  }

  async function handleConfirmSalesSource() {
    setIsLoadingChangeSalesSource(true);
    try {
      await appointmentAPI.patch(appointmentId!, {
        sales_source: preSelected,
      });
      onChange(preSelected!);
      setSelected(preSelected);
      message.success("Sales source cambiado");
    } catch (err) {
      notification.error({
        message: "No se pudo modificar el sales source",
        description: <div className="flex text-g1">{(err as Error).toString()}</div>,
        duration: 10,
      });
      setSelected(salesSource);
    } finally {
      setOpen(false);
      setIsLoadingChangeSalesSource(false);
    }
  }

  async function fetch() {
    try {
      setIsLoading(true);
      const res = await appointmentService.fetchSalesSources();
      const clientsRes = await alliancesService.fetchClients();
      const oldSaleSources: string[] = res.data.data as string[];
      const clients = clientsRes.data;
      const clientsSalesSource = clients.map((client) => client.sales_source);
      const mergedSaleSources = new Set(oldSaleSources.concat(newSalesSources).concat(clientsSalesSource));
      const mergedSalesSourceSorted = Array.from(mergedSaleSources)
        .sort((a, b) => {
          return a.localeCompare(b);
        })
        .map((item) => {
          const value = clients.find((item1) => item1.sales_source == item);
          if (value) {
            return {
              label: value.name,
              value: item,
            };
          } else {
            return { label: item, value: item };
          }
        });
      setList(mergedSalesSourceSorted);
    } catch (err) {
      notification.error({ message: "Ocurrió un error", description: (err as Error).toString(), duration: 10 });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (salesSource) {
      setSelected(salesSource);
    }
  }, [salesSource]);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={clsx("w-full flex flex-col gap-y-1", className)}>
      <label className="text-g3 font-medium text-sm">Sales source</label>
      {externalIsLoading && (
        <Skeleton.Input
          active
          className="!h-8 !w-full"
        />
      )}
      <div className={clsx("w-full flex items-center gap-x-2", externalIsLoading && "hidden")}>
        <Popconfirm
          title="Confirmar acción"
          description={
            <div className="text-g2">
              ¿Cambiar <b className="text-g1 font-medium">{selected}</b> por{" "}
              <b className="text-g1 font-medium">{preSelected}</b>?
            </div>
          }
          placement="top"
          open={open}
          okText="Sí, quiero cambiarlo"
          cancelText="Cancelar"
          okButtonProps={{ loading: isLoadingChangeSalesSource, className: "bg-examedi-blue-strong" }}
          onConfirm={handleConfirmSalesSource}
          onCancel={handleOnCancelChange}
          cancelButtonProps={{ disabled: isLoadingChangeSalesSource }}
        >
          <Select
            showSearch
            value={{ label: selected, value: selected }}
            size="middle"
            loading={isLoading}
            notFoundContent={
              isLoading ? (
                <div className="w-full flex items-center gap-x-2">
                  <Spin
                    spinning
                    size="small"
                  />
                  <span className="text-gray-3 text-sm">Solicitando listado de sales source...</span>
                </div>
              ) : (
                "No se encontraron coincidencias"
              )
            }
            placeholder="Seleccione"
            optionFilterProp="children"
            options={isLoading ? [] : list}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase()) ||
              (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleChange}
            labelRender={(item) => (
              <div className="w-full flex gap-x-2 items-center">
                <div className="text-g1 w-full text-md">{item.label}</div>
                <SalesSourceImageLoad salesSource={item.value as string} />
              </div>
            )}
            optionRender={(item) => (
              <div
                key={item.value}
                className="w-full truncate flex-col flex"
              >
                <div className="text-gray-2 text-sm font-medium leading-5 line-clamp-2">{item.label}</div>
                <div className="text-gray-3 font-light text-xs leading-4">{item.value}</div>
              </div>
            )}
            className="w-full"
          />
        </Popconfirm>
      </div>
    </div>
  );
}

export default SalesSourceSelect;
