import styled from "styled-components";

export const ContentWrapper = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  @media (max-width: 1500px) {
    grid-template-columns: 1fr;
  }
`;

export const Content = styled.div`
  background-color: whitesmoke;
  border-radius: 1rem;
  padding: 1rem;
  display: inline-block;

  @media (min-width: 1120px) {
    flex-basis: 45%;
  }

  @media (max-width: 1120px) {
    flex-grow: 1;
  }

  p {
    margin: 0.5rem;
    font-weight: 100;
  }
`;

export const ContentTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PatientContentTitle = styled.h3`
  margin-bottom: 6px;
  ${(props: { active: boolean }) => (!props.active ? "color: #D1CED1;" : "")}
  ${(props: { active: boolean }) => (props.active ? "margin-top: 30px;" : "")}
`;

export const PatientActionSection = styled.h3`
  margin-top: 2rem;
`;

export const PatientActionModal = styled.div`
  padding: 2rem;
`;

export const PatientActionModalTitle = styled.div`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
`;

export const PatientActionModalBody = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

export const LoaderWrapper = styled.div`
  padding: 3rem;
`;
