import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import styled from "styled-components";
import Swal from "sweetalert2";

import Flex from "~/components/Containers/Flex";
import appointmentService from "~/utils/api/v1/appointmentService";
import nurseService from "~/utils/api/v1/nurseService";
import NurseAPI from "~/utils/api/v2/nurse";

const LoaderWrapper = styled.div`
  padding: 3rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 3rem 1rem;
`;

const ContentWrapper = styled.div`
  padding: 0rem 0rem 2rem;
`;

interface Props {
  setError: (error: any) => void;
  setOpen: (newState: boolean) => void;
  id: string;
  beginDate: string;
}

const SuperadminReassign = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [possibleNurses, setPossibleNurses] = useState<any[]>([]);
  const [selectedNurseId, setSelectedNurseId] = useState<string>("");

  const fetchNurses = async (): Promise<void> => {
    setLoading(true);
    try {
      const req = await nurseService.fetchActiveNurses();
      setPossibleNurses(req.data.data);
      setSelectedNurseId(req.data.data[0].id);
    } catch (err) {
      console.log(err);
      props.setError(err);
    }
    setLoading(false);
  };

  const changeNurse = async (): Promise<void> => {
    setLoading(true);
    try {
      const servicesRes = await NurseAPI.checkServices(selectedNurseId, props.id);
      if (!servicesRes) {
        props.setError("Algo salió mal, intenta más tarde. Contáctate con soporte si continúa pasando");
        return;
      }

      if (!servicesRes.does_services) {
        const proceedAnywaysServices = await Swal.fire({
          title: "Este HT no hace todos los servicios. Continuar de todas maneras?",
          icon: "warning",
          showCancelButton: true,
        });

        if (!proceedAnywaysServices.isConfirmed) {
          setLoading(false);
          props.setOpen(false);
          return;
        }
      }

      const availableRes = await NurseAPI.checkAvailable(props.id, props.beginDate, selectedNurseId);
      if (!availableRes) {
        props.setError("Algo salió mal, intenta más tarde. Contáctate con soporte si continúa pasando");
        return;
      }

      if (!availableRes.available) {
        const proceedAnywaysAvailable = await Swal.fire({
          title: "Este HT no tiene disponibilidad para este horario. Continuar de todas maneras?",
          icon: "warning",
          showCancelButton: true,
        });

        if (!proceedAnywaysAvailable.isConfirmed) {
          setLoading(false);
          props.setOpen(false);
          return;
        }
      }

      // set the new nurse
      await appointmentService.reassignAppointment(props.id, {
        nurse: selectedNurseId,
      });
    } catch (err) {
      console.log(err);
      props.setError(err);
    }
    // close the popup
    setLoading(false);
    props.setOpen(false);
  };

  useEffect(() => {
    fetchNurses();
  }, []);

  return (
    <>
      <DialogContent>
        {loading && (
          <Flex
            direction="column"
            align="center"
          >
            <LoaderWrapper>
              <CircularProgress color="primary" />
            </LoaderWrapper>
          </Flex>
        )}
        {!loading && (
          <ContentWrapper>
            <FormWrapper>
              <FormControl>
                <InputLabel>Tomador</InputLabel>
                <Select
                  value={selectedNurseId}
                  onChange={(e: any) => {
                    setSelectedNurseId(e.target.value);
                  }}
                >
                  {possibleNurses.map((item, key) => {
                    return (
                      <MenuItem
                        key={key}
                        value={item.id}
                      >
                        {" "}
                        {item.names} {item.last_names}{" "}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </FormWrapper>
          </ContentWrapper>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setOpen(false);
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          onClick={changeNurse}
          color="primary"
          disabled={loading ? true : false}
        >
          Cambiar
        </Button>
      </DialogActions>
    </>
  );
};

export default SuperadminReassign;
