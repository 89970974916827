import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Chip,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AxiosError } from "axios";
import { navigate } from "gatsby-link";
import { AiOutlineFilePdf } from "react-icons/ai";
import styled from "styled-components";
import Swal from "sweetalert2";

import AddPreadmisionCodeModal from "~/components/Appointments/Detail/AddPreadmisionCodeModal";
import AddServices from "~/components/Appointments/Detail/AddServices";
import EditItemInsurance from "~/components/Appointments/Detail/EditInsurance/EditItemInsurance";
import SampleIdentifierEditor from "~/components/Appointments/Detail/SampleIdentifierEditor";
import ResultDisplayAndUpload from "~/components/Appointments/ResultDisplayAndUpload";
import { Content, PatientContentTitle } from "~/components/Appointments/Styles/Content";
import { FileCell } from "~/components/Appointments/Styles/UploadImages";
import TotalpackPatientInfo from "~/components/Appointments/TotalpackPatientInfo";
import { UploadDoctorInfo } from "~/components/Appointments/UploadDoctorInfo";
import UploadAndDisplayImage from "~/components/Appointments/UploadImage";
import UploadMedicalOrder from "~/components/Appointments/UploadMedicalOrder";
import Bold from "~/components/Typography/Bold";
import { CLINICA_ALEMANA, LABORATORIO_CHOPO } from "~/constants/salesSources";
import { appointmentAPI, appointmentPatientAPI } from "~/utils/api/v2";
import { SupportedCountry } from "~/utils/constants/places";
import { calculateAge, formatDate } from "~/utils/dates/formatDate";
import { AppointmentPatientV2, MedicalOrderLink } from "~/utils/interfaces/AppointmentPatient";
import { capitalize, insuranceNameText } from "~/utils/texts";

const CustomDeleteIcon = styled(DeleteIcon)`
  color: #0000008a;
  cursor: pointer;
`;

type PatientInformationProps = {
  appointmentPatient: AppointmentPatientV2;
  setAppointmentPatients: Dispatch<SetStateAction<AppointmentPatientV2[]>>;
  appointmentId: string;
  country: string;
  hasImage: boolean;
  setHasImage: (hasImage: boolean) => void;
  setReloadOperativeData: Dispatch<SetStateAction<boolean>> | undefined;
  dealGroupedMedicalOrders: string[];
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
  salesSource?: string;
  className?: string;
};

const PatientInformation = ({
  appointmentPatient,
  setAppointmentPatients,
  appointmentId,
  country,
  hasImage,
  setHasImage,
  setReloadOperativeData,
  dealGroupedMedicalOrders,
  setError,
  salesSource,
  className,
}: PatientInformationProps) => {
  const [fileArray, setFileArray] = useState<Array<File> | undefined>([]);
  const [hasFonasaService, setHasFonasaService] = useState<boolean>(false);
  const [groupedMedicalOrders, setGroupedMedicalOrders] = useState<string[]>([]);
  const [isPreadmisionModalOpen, setIsPreadmisionModalOpen] = useState<boolean>(false);
  const [preadmisionCode, setPreadmisionCode] = useState<string>("");

  const patient = appointmentPatient.patient;
  const symptoms = appointmentPatient.symptoms;
  const reason = appointmentPatient.reason;
  const comments = appointmentPatient.comments;
  const active = appointmentPatient.active;
  const medicalOrdersUploadedByPatients = appointmentPatient.medical_order_links.filter(
    (medicalOrderLink: MedicalOrderLink) => {
      return !medicalOrderLink.triggered_from.includes("medical-order-bot");
    },
  );
  const referralURL = "https://referidos.examedi.com/referred/?code=";
  const onReferralClick = async (e) => {
    e.preventDefault();
    await navigator.clipboard.writeText(referralURL + patient.id);
    alert("Link de referidos copiado con éxito");
  };
  const reloadAppointmentPatient = async () => {
    const response = await appointmentPatientAPI.patch(appointmentPatient.id, {});
    setAppointmentPatients((prev) => {
      const index = prev.indexOf(appointmentPatient);
      const newAppointmentPatients = [...prev];
      newAppointmentPatients[index] = response.data;
      return newAppointmentPatients;
    });
  };
  const changePatientStatus = async (active) => {
    try {
      const check = await Swal.fire({
        title: `¿Estás seguro que deseas ${!active ? "des" : ""}activar este paciente?`,
        icon: "warning",
        showCancelButton: true,
      });
      if (!check.isConfirmed) {
        return;
      }
      const response = await appointmentPatientAPI.patch(appointmentPatient.id, {
        active,
      });
      setAppointmentPatients((prev) => {
        const index = prev.indexOf(appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = response.data;
        return newAppointmentPatients;
      });

      await Swal.fire({
        title: "¡Hecho!",
      });
      setReloadOperativeData && setReloadOperativeData(true);
    } catch (e) {
      setError(e);
    }
  };

  const deleteItem = async (apptPatientItemId: number) => {
    try {
      const check = await Swal.fire({
        title: "¿Estás seguro que deseas eliminar este servicio?",
        icon: "warning",
        showCancelButton: true,
      });
      if (!check.isConfirmed) {
        return;
      }

      await appointmentPatientAPI.deleteItem(appointmentPatient.id, apptPatientItemId);
      setAppointmentPatients((prev) => {
        const index = prev.indexOf(appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = {
          ...newAppointmentPatients[index],
          appointment_patient_items: newAppointmentPatients[index].appointment_patient_items.filter(
            (aps) => aps.id !== apptPatientItemId,
          ),
        };
        return newAppointmentPatients;
      });

      await Swal.fire({
        title: "¡Hecho!",
      });
    } catch (e) {
      setError(e);
    }
  };

  const deletePack = async (apptPatientPackId: number) => {
    try {
      const check = await Swal.fire({
        title: "¿Estás seguro que deseas eliminar este pack?",
        icon: "warning",
        showCancelButton: true,
      });
      if (!check.isConfirmed) {
        return;
      }

      await appointmentPatientAPI.deletePack(appointmentPatient.id, apptPatientPackId);
      setAppointmentPatients((prev) => {
        const index = prev.indexOf(appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = {
          ...newAppointmentPatients[index],
          appointment_patient_item_packs: newAppointmentPatients[index].appointment_patient_item_packs.filter(
            (apptPatientPack) => apptPatientPack.id !== apptPatientPackId,
          ),
        };
        return newAppointmentPatients;
      });

      await Swal.fire({
        title: "¡Hecho!",
      });
    } catch (e) {
      setError(e);
    }
  };

  const changeAllItemsInsurance = async (isFonasa: boolean, insurance: string) => {
    try {
      const response = await appointmentPatientAPI.patch(appointmentPatient.id, {
        items: appointmentPatient.appointment_patient_items.map((appointmentPatientItem) => {
          return {
            id: appointmentPatientItem.item.id,
            is_fonasa: isFonasa,
            insurance: insurance,
          };
        }),
      });
      setAppointmentPatients((prev) => {
        const index = prev.indexOf(appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = response.data;
        return newAppointmentPatients;
      });
      await Swal.fire({
        title: "¡Previsión modificada con éxito!",
      });
      window.location.reload();
    } catch (e) {
      setError(e);
    }
  };

  const triggerOMReady = async () => {
    await appointmentAPI.sendOMReady({
      appointment_id: appointmentId,
    });
  };

  const changeItemInsurance = async (appointmentPatientItemId: number, isFonasa: boolean, insurance: string) => {
    try {
      const response = await appointmentPatientAPI.patch(appointmentPatient.id, {
        items: appointmentPatient.appointment_patient_items.map((appointmentPatientItem) => {
          if (appointmentPatientItem.id === appointmentPatientItemId) {
            return {
              id: appointmentPatientItem.item.id,
              is_fonasa: isFonasa,
              insurance: insurance,
            };
          } else {
            return {
              id: appointmentPatientItem.item.id,
              is_fonasa: appointmentPatientItem.is_fonasa,
              insurance: appointmentPatientItem.insurance,
            };
          }
        }),
      });
      setAppointmentPatients((prev) => {
        const index = prev.indexOf(appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = response.data;
        return newAppointmentPatients;
      });
      await Swal.fire({
        title: "¡Previsión modificada con éxito!",
      });
    } catch (e) {
      setError(e);
    }
  };

  const fastingHours = useMemo(() => {
    const fastHoursServices = appointmentPatient.appointment_patient_items.reduce((prev, curr) => {
      return prev > curr.item.fast_hours ? prev : curr.item.fast_hours;
    }, 0);
    const fastHoursPack = appointmentPatient.appointment_patient_item_packs.reduce((prev, curr) => {
      return prev > curr.pack.fast_hours ? prev : curr.pack.fast_hours;
    }, 0);
    return fastHoursServices > fastHoursPack ? fastHoursServices : fastHoursPack;
  }, [appointmentPatient]);

  useEffect(() => {
    if (
      country === "Chile" &&
      appointmentPatient.appointment_patient_items.find(
        (appointmentPatientItem) => appointmentPatientItem.is_fonasa,
      ) !== undefined
    ) {
      setHasFonasaService(true);
    } else {
      setHasFonasaService(false);
    }
  }, [appointmentPatient.appointment_patient_items, country]);

  useEffect(() => {
    setGroupedMedicalOrders([...appointmentPatient.grouped_orden_medica, ...dealGroupedMedicalOrders]);
  }, [appointmentPatient]);

  useEffect(() => {
    setPreadmisionCode(appointmentPatient.last_mile_appointment_patient?.folio || "");
  }, [appointmentPatient.last_mile_appointment_patient]);

  return (
    <Content className="w-full">
      <div
        className={className}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h4 style={!active ? { color: "#D1CED1" } : {}}>
          {patient.first_name} {patient.last_name} {!active && "desactivado"}
        </h4>
        <div>
          {active ? (
            <Button
              color="primary"
              variant="text"
              size="small"
              onClick={() => {
                changePatientStatus(false);
              }}
            >
              &nbsp;Desactivar&nbsp;
            </Button>
          ) : (
            <Button
              color="primary"
              variant="text"
              size="small"
              onClick={() => {
                changePatientStatus(true);
              }}
            >
              &nbsp;Activar&nbsp;
            </Button>
          )}
          {active && (
            <Button
              color="primary"
              variant="text"
              size="small"
              onClick={() => {
                navigate(
                  `/patients/${patient.id}/edit?return_url=${location.pathname}&appointment_patient=${appointmentPatient.id}&appointment=${appointmentId}`,
                );
              }}
            >
              &nbsp;Corregir datos&nbsp;
            </Button>
          )}
          {active && [CLINICA_ALEMANA, LABORATORIO_CHOPO].includes(salesSource || "") && (
            <Button
              color="primary"
              variant="text"
              size="small"
              onClick={() => setIsPreadmisionModalOpen(true)}
            >
              {appointmentPatient.last_mile_appointment_patient ? "Editar" : "Añadir"}{" "}
              {salesSource === CLINICA_ALEMANA ? "código preadmisión" : "folio"}
            </Button>
          )}
        </div>
      </div>

      {active && (
        <Accordion className="w-full">
          <AccordionSummary
            className="w-full"
            expandIcon={<ExpandMoreIcon />}
          >
            <>
              {" "}
              {capitalize(patient.document_type)}: {patient.document_number}
            </>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <p style={!active ? { color: "#D1CED1" } : {}}>
                <Bold>Nombre: </Bold> {patient.first_name} {patient.second_name} {patient.last_name}{" "}
                {patient.second_last_name}
              </p>
              <p style={!active ? { color: "#D1CED1" } : {}}>
                <Bold>{capitalize(patient.document_type)}: </Bold> {patient.document_number}
              </p>
              <p style={!active ? { color: "#D1CED1" } : {}}>
                <Bold>Sexo: </Bold> {patient.gender === "male" ? "Masculino" : "Femenino"}
              </p>
              <p style={!active ? { color: "#D1CED1" } : {}}>
                <Bold>Teléfono: </Bold>{" "}
                <a
                  target="_blank"
                  href={`https://wa.me/${patient.phone}`}
                  rel="noreferrer"
                >
                  {patient.phone}
                </a>
              </p>
              <p style={!active ? { color: "#D1CED1" } : {}}>
                <Bold>Email: </Bold> {patient.email}
              </p>
              <p>
                <Bold>Edad: </Bold> {calculateAge(patient.date_of_birth)} años
              </p>
              <p>
                <Bold>Fecha de nacimiento: </Bold> {formatDate(patient.date_of_birth)}
              </p>
              {patient.insurance && (
                <p>
                  <Bold>Previsión: </Bold>
                  <Chip
                    label={patient.insurance}
                    color="primary"
                  />
                </p>
              )}

              {!!symptoms && (
                <p>
                  <Bold>Síntomas: </Bold> {symptoms}
                </p>
              )}
              {!!reason && (
                <p>
                  <Bold>Motivo de toma de examen: </Bold> {reason}
                </p>
              )}
              {!!comments && (
                <p>
                  <Bold>Otros comentarios: </Bold>
                  {comments}
                </p>
              )}
              <p>
                <Bold>URL Contacto estrecho: </Bold>
                <Tooltip
                  title="Click para copiar"
                  placement="top"
                  arrow
                >
                  <Link
                    href="#"
                    onClick={onReferralClick}
                    style={{ fontSize: "8px" }}
                  >
                    {referralURL + patient.id}
                  </Link>
                </Tooltip>
              </p>
              {!!fastingHours && (
                <p>
                  <Bold>Horas de ayuno: </Bold> {fastingHours}
                </p>
              )}
              {!!appointmentPatient.medical_order_observations && (
                <p>
                  <Bold>Observaciones orden médica: </Bold> {appointmentPatient.medical_order_observations}
                </p>
              )}
              {appointmentPatient.last_mile_appointment_patient?.folio && (
                <p>
                  <Bold>Código de preadmisión / Folio: </Bold> {appointmentPatient.last_mile_appointment_patient?.folio}
                </p>
              )}

              <PatientContentTitle active={active}>Exámenes Solicitados</PatientContentTitle>

              <Grid
                container
                spacing={1}
                style={{ padding: "4px", marginBottom: "1rem" }}
              >
                {appointmentPatient.appointment_patient_items.map((appointmentPatientItem, index: number) => {
                  return (
                    <Grid
                      item
                      md={4}
                      key={index}
                    >
                      <Card style={{ minHeight: "17rem" }}>
                        <List>
                          <ListItem dense>
                            <ListItemText
                              primary={
                                <span>
                                  {appointmentPatientItem.item.name}
                                  {appointmentPatientItem.item.lab &&
                                    ` - ${appointmentPatientItem.item.lab.display_name}`}
                                </span>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                  >
                                    {appointmentPatientItem.item.external_reference &&
                                      `Código: ${appointmentPatientItem.item.external_reference} | `}
                                  </Typography>
                                  {country !== "México" && (
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      Previsión:{" "}
                                      {!appointmentPatientItem.is_fonasa && (
                                        <span>{insuranceNameText(appointmentPatientItem.insurance)}</span>
                                      )}
                                      {appointmentPatientItem.is_fonasa &&
                                        ` Fonasa - Código ${appointmentPatientItem.item.code}`}
                                    </Typography>
                                  )}
                                </React.Fragment>
                              }
                            />

                            <CustomDeleteIcon onClick={async () => deleteItem(appointmentPatientItem.id)} />
                          </ListItem>
                          {country !== "México" && (
                            <EditItemInsurance
                              mode="singleTest"
                              changeAllItemsInsurance={changeAllItemsInsurance}
                              changeItemInsurance={changeItemInsurance}
                              is_fonasa={appointmentPatientItem.is_fonasa}
                              insurance={appointmentPatientItem.insurance}
                              appointmentPatientItemId={appointmentPatientItem.id}
                            />
                          )}
                        </List>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
              <AddServices
                appointmentPatient={appointmentPatient}
                setAppointmentPatients={setAppointmentPatients}
                country={country as SupportedCountry}
                onlyPacks={false}
                salesSource={salesSource}
              />
              <Button
                color="primary"
                variant="contained"
                className="w-full mt-[5px] bg-examedi-blue-strong"
                onClick={async () =>
                  Swal.fire({
                    icon: "warning",
                    title: "Enviar orden medica por whatsapp?",
                    text: "Esta acción no se puede deshacer",
                    confirmButtonText: "Enviar",
                    showCancelButton: true,
                    cancelButtonText: "Cancelar",
                  }).then(async (res) => {
                    if (res.value) {
                      const triggered = await triggerOMReady();
                      Swal.fire({
                        icon: "success",
                        title: "Flujo de Whatsapp Enviado",
                      });
                    }
                  })
                }
              >
                Asignar servicios para Whatsapp
              </Button>
              {country !== "México" && (
                <EditItemInsurance
                  mode="allTests"
                  changeAllItemsInsurance={changeAllItemsInsurance}
                  changeItemInsurance={changeItemInsurance}
                  is_fonasa={true}
                  insurance="fonasa"
                />
              )}

              <PatientContentTitle active={active}>Número de orden</PatientContentTitle>
              <SampleIdentifierEditor
                sampleIdentifier={appointmentPatient.sample_identifier}
                appointmentPatientId={appointmentPatient.id}
              />
              <PatientContentTitle active={active}>Packs Solicitados</PatientContentTitle>
              <AddServices
                appointmentPatient={appointmentPatient}
                setAppointmentPatients={setAppointmentPatients}
                country={country as SupportedCountry}
                onlyPacks={true}
                salesSource={salesSource}
              />
              <Grid style={{ padding: "4px" }}>
                {appointmentPatient.appointment_patient_item_packs.map((appointmentPatientItemPack, index: number) => {
                  return (
                    <Grid
                      item
                      md={6}
                      key={index}
                    >
                      <Card style={{ backgroundColor: "khaki" }}>
                        <List>
                          <ListItem dense>
                            <ListItemText
                              primary={
                                <div className="flex flex-column">
                                  <div>Pack: {appointmentPatientItemPack.pack.name}</div>
                                </div>
                              }
                              // TODO: serialize the items in the pack and get the service code
                              secondary={
                                <div className="flex flex-col">
                                  <div className="flex flex-col my-2">
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      Qué incluye:
                                    </Typography>
                                    {appointmentPatientItemPack.pack.detailed_items.map((detailedItem) => (
                                      <Typography
                                        component="span"
                                        variant="body2"
                                      >
                                        - {detailedItem.name}
                                      </Typography>
                                    ))}
                                  </div>
                                  {country !== "México" && (
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      {appointmentPatientItemPack.is_fonasa
                                        ? "Previsión: Fonasa - Código"
                                        : `Previsión: Particular`}
                                    </Typography>
                                  )}
                                  {appointmentPatientItemPack.pack.external_reference && (
                                    <Typography
                                      component="span"
                                      variant="body2"
                                    >
                                      Código: {appointmentPatientItemPack.pack.external_reference}
                                    </Typography>
                                  )}
                                </div>
                              }
                            />
                            <CustomDeleteIcon onClick={async () => deletePack(appointmentPatientItemPack.id)} />
                          </ListItem>
                        </List>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>

              <div>
                <p>
                  <Bold>Imágenes de Muestra: </Bold>
                </p>
                <UploadAndDisplayImage
                  appointmentId={appointmentId}
                  appointmentPatient={appointmentPatient}
                  hasImage={hasImage}
                  setHasImage={setHasImage}
                />
              </div>
              <div>
                <PatientContentTitle active={active}>Imágenes de orden médica</PatientContentTitle>
                <UploadMedicalOrder
                  setGroupedMedicalOrders={setGroupedMedicalOrders}
                  groupedMedicalOrders={groupedMedicalOrders}
                  fileArray={fileArray}
                  appointmentPatient={appointmentPatient}
                  setFileArray={setFileArray}
                />
              </div>
              <div>
                <PatientContentTitle active={active}>Orden médicas subidas por paciente</PatientContentTitle>
                {medicalOrdersUploadedByPatients.map((medicalOrder, i: number) => {
                  return (
                    <a
                      key={i}
                      target="_blank"
                      href={medicalOrder.file_link}
                      rel="noreferrer"
                    >
                      <FileCell>
                        <AiOutlineFilePdf size={38} />
                        <p>Archivo {i + 1}</p>
                      </FileCell>
                    </a>
                  );
                })}
              </div>
              <UploadDoctorInfo
                appointmentPatient={appointmentPatient.id}
                active={active}
              />
              <ResultDisplayAndUpload
                appointmentId={appointmentId}
                patientId={patient.id}
                country={country}
                appointmentPatientItems={appointmentPatient.appointment_patient_items}
                appointmentPatientPacks={appointmentPatient.appointment_patient_item_packs}
              />
              {appointmentPatient.totalpack_request && <TotalpackPatientInfo appointmentPatient={appointmentPatient} />}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
      <AddPreadmisionCodeModal
        salesSource={salesSource || ""}
        isOpen={isPreadmisionModalOpen}
        onClose={() => setIsPreadmisionModalOpen(false)}
        code={preadmisionCode}
        setCode={setPreadmisionCode}
        appointmentPatientId={appointmentPatient.id}
        onSuccess={reloadAppointmentPatient}
      />
    </Content>
  );
};

export default PatientInformation;
