import { ReceiptType } from "~/utils/interfaces/Appointment";
import { numberWithPeriods } from "~/utils/numbers";

export const newSalesSources: string[] = [
  "ubermx",
  "medicalima",
  "acalisconventry",
  "tenpo",
  "pediatriaysalud",
  "centro-medico-familiar",
];

/* Steps should be fetched from the API eventually */
export const timelineSteps = [
  "booked",
  "contacted",
  "on-the-way",
  "arrived",
  "visited",
  "receipt-sent",
  "results-sent",
];

export const stepsLabels = [
  "Agendado",
  "Contactado",
  "En camino",
  "En domicilio",
  "Visitado",
  "Boleta enviada",
  "Resultados enviados",
];

export const receiptTypes = {
  [ReceiptType.DELIVERY]: "Boleta de costo a domicilio",
  [ReceiptType.LAB_WORK]: "Boleta de exámenes (Reembolso)",
  [ReceiptType.EXAMEDI_VACCINE_WORK]: "Boleta de vacunas realizadas por Examedi",
  [ReceiptType.EXAMEDI_ECG_WORK]: "Boleta de exámenes ECG realizados por Examedi",
  [ReceiptType.EXAMEDI_ANTIGEN_WORK]: "Boleta de exámenes Antígenos realizados por Examedi",
  [ReceiptType.EXAMEDI_NEUROBIONTA_WORK]: "Boleta de exámenes Neurobionta realizados por Examedi",
  [ReceiptType.EXAMEDI_ALLERGIES_WORK]: "Boleta de Packs Alergias realizados por Examedi",
  [ReceiptType.VOUCHER]: "Bono de fonasa",
  [ReceiptType.FONASA_VOUCHER]: "Bono de fonasa",
  [ReceiptType.TOTALPACK_VOUCHER]: "Bono totalpack",
  [ReceiptType.KINESOLOGY]: "Boleta Kinesiología",
};

export const sentInfo = ({ date, time }) => ({
  title: "Estado del envío",
  text: `El ticket se envió el ${date} a las ${time} (hora México).`,
});

export const sentErrorInfo = {
  title: "Estado del envío",
  text: "El ticket no se ha podido enviar.",
};

export const folioInfo = (folio: string | undefined) => ({
  title: "Nº folio",
  text: folio || "Sin número de folio.",
});

export const internalErrorInfo = (message: string | undefined) => ({
  title: "Error interno",
  text: message || "Ocurrió un error en nuestro sistema.",
});

export const paymentPendingInfo = {
  title: "Pago pendiente",
  text: "El paciente presenta un pago pendiente, por lo que el ticket no se ha podido enviar.",
};

export const scheduleInfo = ({ date, time }) => ({
  title: "Fecha envío",
  text: `El ticket será enviado el ${date} a las ${time} (hora México).`,
});

export const amountPaidInfo = (amountPaid: number) => ({
  title: "Cantidad pagada",
  text: `$${numberWithPeriods(amountPaid, "mx")} MXN`,
});
