import React, { useMemo } from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import Bold from "~/components/Typography/Bold";
import useAssistedSchedulingState from "~/stores/AssistedSchedulingStore";
import { CartItemObjectType } from "~/utils/interfaces/cartItem";
import { CartLabService } from "~/utils/interfaces/LabServices";
import { currencyFormat } from "~/utils/numbers";

interface Props {
  options: CartItemObjectType[];
  fonasa?: boolean;
  index: number;
  refetching?: boolean;
  query: string;
  showBasePrice: boolean;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  addService?: (index: number, newService: CartLabService) => void;
  onlyPacks: boolean;
}

function ServicesSearch(props: Props) {
  const addService = props.addService ?? useAssistedSchedulingState((state) => state.addService);

  const handleChange = (_, newValue) => {
    addService(props.index, newValue);
    props.setQuery("");
  };

  const displayableServices = useMemo(
    () => (props.fonasa ? props.options?.filter((val: CartItemObjectType) => val.allowFonasaPrice) : props.options),
    [props.fonasa, props.options],
  );

  return (
    <>
      <Autocomplete
        disableClearable
        options={displayableServices}
        getOptionLabel={(option: CartItemObjectType) => option.name}
        onChange={handleChange}
        inputValue={props.query}
        onInputChange={(_, newValue: string) => {
          props.setQuery(newValue);
        }}
        noOptionsText={props.refetching ? "Buscando..." : "No hay resultados :("}
        renderOption={(option: CartItemObjectType, { inputValue }) => {
          const matches = match(option.displayName || option.name, inputValue);
          const parts = parse(option.displayName || option.name, matches);
          const price = option.price({ fonasa: props.fonasa });
          const isAlteredPrice = (props.fonasa && option.allowFonasaPrice) || option.isPromotional;
          return (
            <div className="w-full flex justify-between">
              <div className="w-1/2 flex">
                {props.showBasePrice && isAlteredPrice && (
                  <Bold className="line-through w-1/2">{currencyFormat(option.basePrice)}</Bold>
                )}
                <Bold className="w-1/2">{currencyFormat(price)}</Bold>
              </div>
              <div className="w-full">
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </div>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Busca y selecciona un ${props.onlyPacks ? "pack" : "servicio"}`}
            variant="outlined"
          />
        )}
      />
    </>
  );
}

export default ServicesSearch;
