import React, { useEffect, useState } from "react";

import clsx from "clsx";

import AppointmentContactChannel from "~/components/AppointmentsV2/AppointmentContactChannel";
import AppointmentFinishedSelect from "~/components/AppointmentsV2/AppointmentFinishedSelect";
import AppointmentHTCard from "~/components/AppointmentsV2/AppointmentHTCard";
import AppointmentManualConfirm from "~/components/AppointmentsV2/AppointmentManualConfirm";
import AppointmentOrigin from "~/components/AppointmentsV2/AppointmentOrigin";
import AppontmentBasicData from "~/components/AppointmentsV2/AppontmentBasicData";
import ClientOriginSelect from "~/components/AppointmentsV2/ClientOriginSelect";
import SalesSourceSelect from "~/components/AppointmentsV2/SalesSourceSelect";
import { AppointmentV2 } from "~/utils/interfaces/Appointment";

type AppointmentViewProps = {
  isLoading?: boolean;
  appointment?: AppointmentV2 | null;
  className?: string;
};

function AppointmentView({ isLoading, appointment: appointmentFormProps, className }: AppointmentViewProps) {
  const [appointment, setAppointment] = useState<AppointmentV2 | null>(null);

  function handleSalesSourceChange(value: string) {
    setAppointment((prevValue) => ({ ...prevValue!, sales_source: value }));
  }

  function handleOriginChange(value: string | null) {
    setAppointment((prevValue) => ({ ...prevValue!, client_origin: value }));
  }

  function handleChangeFinished(value: boolean) {
    setAppointment((prevValue) => ({ ...prevValue!, finished: value }));
  }

  useEffect(() => {
    if (appointmentFormProps) {
      setAppointment(appointmentFormProps);
    }
  }, [appointmentFormProps]);

  return (
    <div className={clsx("w-full flex flex-col gap-y-7", className)}>
      <div className="flex justify-between select-none">
        <AppontmentBasicData
          isLoading={isLoading}
          appointment={appointment}
        />
        <AppointmentHTCard
          isLoading={isLoading}
          appointment={appointment}
        />
      </div>
      <div className="w-full grid grid-cols-12 gap-10">
        <SalesSourceSelect
          isLoading={isLoading}
          appointmentId={appointment?.id}
          salesSource={appointment?.sales_source}
          className="col-span-4"
          onChange={handleSalesSourceChange}
        />
        <AppointmentOrigin
          isLoading={isLoading}
          appointment={appointment}
          className="col-span-3"
        />
        <AppointmentContactChannel
          isLoading={isLoading}
          appointment={appointment}
          className="col-span-2"
        />
        {!(
          (!appointment?.sales_source || appointment?.sales_source === "marketplace") &&
          !appointment?.client_origin
        ) && (
          <ClientOriginSelect
            clientOrigin={appointment?.client_origin}
            salesSource={appointment?.sales_source}
            className="col-span-3 col-start-1"
            onChange={handleOriginChange}
            appointmentId={appointment?.id}
          />
        )}
        <AppointmentFinishedSelect
          isLoading={isLoading}
          appointmentId={appointment?.id}
          apptStatus={appointment?.status}
          isFinished={appointment?.finished}
          className="col-span-3"
          onChange={handleChangeFinished}
        />
        <AppointmentManualConfirm
          className={clsx("col-span-2 hidden", appointment?.status == "scheduled" && "block")}
        />
      </div>
    </div>
  );
}

export default AppointmentView;
