import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import styled from "styled-components";

import Flex from "~/components/Containers/Flex";
import appointmentService from "~/utils/api/v1/appointmentService";
import { AvailabilityNurseV2 } from "~/utils/interfaces/Nurse";

const LoaderWrapper = styled.div`
  padding: 3rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 3rem 1rem;
`;

const UnavailableNurse = styled.span`
  color: red;
`;

const ContentWrapper = styled.div`
  padding: 0rem 0rem 2rem;
`;

interface Props {
  setError: (error: any) => void;
  setOpen: (newState: boolean) => void;
  id: string;
}

const NormalReassign = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [possibleNurses, setPossibleNurses] = useState<AvailabilityNurseV2[]>([]);
  const [selectedNurseId, setSelectedNurseId] = useState<string>("");
  const [error, setError] = useState<string>("");

  const fetchPossibleNurses = async (): Promise<void> => {
    setLoading(true);
    try {
      const req = await appointmentService.fetchPossibleNursesV2(props.id);
      if (req.data.length > 0) {
        setPossibleNurses(req.data);
        setSelectedNurseId(req.data[0].id);
      } else {
        setError("No hay HTs disponibles para reasignar.");
      }
    } catch (err) {
      console.log(err);
      props.setError(err);
    }
    setLoading(false);
  };

  const changeSelected = (event: React.ChangeEvent<{ value: string }>): void => {
    setError("");
    setSelectedNurseId(event.target.value);
    // find the selected nurse and set it
    const nurse = possibleNurses.find((element) => element.id === event.target.value);
  };

  const changeNurse = async (): Promise<void> => {
    setLoading(true);
    // set the new nurse
    try {
      const req = await appointmentService.reassignAppointment(props.id, {
        nurse: selectedNurseId,
      });
    } catch (err) {
      console.log(err);
      props.setError(err);
    }
    // close the popup
    setLoading(false);
    props.setOpen(false);
    location.reload();
  };

  useEffect(() => {
    fetchPossibleNurses();
  }, []);

  return (
    <>
      <DialogContent>
        {loading && (
          <Flex
            direction="column"
            align="center"
          >
            <LoaderWrapper>
              <CircularProgress color="primary" />
            </LoaderWrapper>
          </Flex>
        )}
        {!loading && (
          <ContentWrapper>
            <FormWrapper>
              <FormControl>
                <InputLabel>Tomador</InputLabel>
                <Select
                  value={selectedNurseId}
                  onChange={changeSelected}
                >
                  {possibleNurses.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                    >
                      {item.user.full_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormWrapper>
            {error && <UnavailableNurse>{error}</UnavailableNurse>}
          </ContentWrapper>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setOpen(false);
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          onClick={changeNurse}
          color="primary"
          disabled={loading ? true : false}
        >
          Cambiar
        </Button>
      </DialogActions>
    </>
  );
};

export default NormalReassign;
